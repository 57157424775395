import { createAction as f } from "@waitroom/react-utils";
import { useSelector as U, useDispatch as g } from "react-redux";
import ct from "deepmerge";
import { produce as ot } from "immer";
import { authApiService as T, authBaseEndpoint as G, httpService as at, braidService as ut, userApiService as L } from "@waitroom/common-api";
import { STORAGE_AUTH_TOKENS as C, LOGGER_SERVICE_AUTH as m, LOGGER_SERVICE_BRAID as z, ERROR_SESSION_ACCESS_UNAUTHORIZED_LOCKED as ht } from "@waitroom/models";
import { tryCatchAsync as F, withLock as V, retry as dt, isFunction as ft } from "@waitroom/utils";
import { jwtDecode as Tt } from "jwt-decode";
import { useMemo as St } from "react";
import { useMutation as P } from "@tanstack/react-query";
import { isErrorCode as lt, getDetailsErrorCode as _t } from "@waitroom/braid";
import { bearerValue as y } from "@waitroom/http-client";
import { createListenerMiddleware as mt } from "@reduxjs/toolkit";
import { getRequestData as O } from "@waitroom/react-query";
const x = "refresh-token", p = "auth/INIT_AUTH", B = "auth/SETUP_AUTH", j = "auth/LOGOUT", q = "auth/SET_CURRENT_USER", W = "auth/UPDATE_CURRENT_USER", Zt = "auth/SET_CURRENT_USER_SUBSCRIPTION_PLAN", K = "auth/SET_AUTH_STATE", Z = "auth/UPDATE_AUTH_STATE", J = "auth/SET_SUBSCRIPTION_PLAN", Q = "auth/REFRESH_SUBSCRIPTION_PLAN", X = "auth/AUTHENTICATED", Et = "auth/UN_AUTHENTICATED", Y = "auth/GET_CURRENT_USER", Jt = f(p), $ = f(B), v = f(
  K
), l = f(Z), kt = f(Y), Qt = f(
  q
), Xt = f(W), Ut = f(j), D = f(X), I = f(Et), tt = f(J), Yt = f(Q);
let o;
const $t = (e) => {
  o = e.logger;
}, At = (e) => e.reduce(
  (t, s) => ({
    ...t,
    [s]: !0
  }),
  {}
), w = {
  userId: void 0,
  isAuthenticated: !1,
  status: "initialized",
  isGuest: !1
}, S = {
  _refreshPromise: void 0,
  _cache: {},
  config: {},
  init: function(e) {
    this.config = e;
  },
  getAnonymous: () => w,
  getDecodedToken: function(e) {
    if (this._cache[e]) return this._cache[e];
    const t = Tt(e);
    if (t)
      return this._cache[e] = t, t;
  },
  setup: async function(e, t = !0) {
    const s = e || this.getStorageTokens();
    if (!(s != null && s.accessToken)) return w;
    const n = this.decode(s, 600);
    n != null && n.accessToken && this.saveStorageTokens({
      accessToken: n.accessToken,
      refreshToken: n.refreshToken
    });
    const i = n || (t ? await this.refresh(s.refreshToken) : void 0);
    return i != null && i.accessToken ? i : this.logout();
  },
  getStorageTokens: function() {
    var e;
    return (e = this.config.storage) == null ? void 0 : e.getParsed(C);
  },
  saveStorageTokens: function(e) {
    var t;
    (t = this.config.storage) == null || t.set(C, e);
  },
  clearStorageTokens: function() {
    var e;
    (e = this.config.storage) == null || e.remove(C);
  },
  isTokenValid: function(e, t = 0) {
    const s = e ? this.getDecodedToken(e) : void 0;
    return !!s && !this.hasExpired(s.exp, t);
  },
  _refreshFn: async function(e) {
    var r;
    if (!e) return;
    const t = await F(
      () => T.refreshToken({
        data: {
          refreshToken: e
        }
      })
    )();
    if ((t == null ? void 0 : t.code) === 401) return;
    const { authToken: s, refreshToken: n } = ((r = t == null ? void 0 : t.data) == null ? void 0 : r.data) || {};
    if (!s) return;
    const i = { accessToken: s, refreshToken: n }, c = this.decode(i);
    if (c)
      return this.saveStorageTokens(i), c;
  },
  refresh: async function(e) {
    var i;
    if (o == null || o.service(m, 1, "Refreshing auth token"), this._refreshPromise) return await this._refreshPromise;
    const t = (i = this.getStorageTokens()) == null ? void 0 : i.refreshToken, s = e || t;
    if (!s) return;
    this._refreshPromise = this._refreshFn(s);
    const n = await this._refreshPromise || await this._refreshFn(t);
    return o == null || o.service(m, 1, "Refreshed auth token", n == null ? void 0 : n.refreshToken), this._refreshPromise = void 0, n;
  },
  /** Decode token with expiration tolerance in seconds */
  decode: function(e, t) {
    const {
      userID: s,
      guestSurrogateID: n,
      guestEmail: i,
      guestFullName: c,
      exp: r,
      roleIDs: u = []
    } = this.getDecodedToken(e.accessToken) || {}, a = s || (n ? String(n) : void 0);
    if (!a || this.hasExpired(r, t)) return;
    const d = !s, _ = {
      ...e,
      userId: a,
      isAuthenticated: !0,
      expires: r,
      agentId: a,
      isGuest: d,
      roles: At(u),
      status: "initialized"
    };
    return d && (_.currentUser = {
      id: String(n),
      firstName: c || "",
      email: i || ""
    }), _;
  },
  logout: async function() {
    return await T.logout({ throwError: !1 }), this.clearStorageTokens(), w;
  },
  /** Check if token has expired with expiration tolerance in seconds */
  hasExpired: (e, t = 0) => !e || Date.now() + t * 1e3 > e * 1e3
}, et = S.getAnonymous(), st = {
  ...et,
  status: void 0
}, te = (e = st, t) => ot(e, (s) => {
  var n;
  switch (t.type) {
    case K:
      return t.payload || et;
    case Z:
      return s = { ...s, ...t.payload }, s;
    case q:
      return s.currentUser = t.payload, s.userId = (n = t.payload) == null ? void 0 : n.id, s;
    case W:
      return t.payload && (s.currentUser = ct(
        s.currentUser || {},
        t.payload
      ), t.payload.id && (s.userId = t.payload.id)), s;
    case J:
      return !s.currentUser || !t.payload || (s.currentUser.subscriptionPlan = t.payload), s;
    default:
      return e;
  }
}), h = (e) => e && e.auth ? e.auth : st, yt = (e) => h(e).isAuthenticated, Pt = (e) => h(e).isGuest, vt = (e) => h(e).status, ee = (e) => h(e).userId, E = (e) => h(e).currentUser, se = (e) => {
  var t;
  return (t = E(e)) == null ? void 0 : t.id;
}, ne = (e) => {
  var t;
  return (t = E(e)) == null ? void 0 : t.customerID;
}, ie = (e) => {
  var t;
  return (t = E(e)) == null ? void 0 : t.email;
}, It = (e) => h(e).roles, nt = (e) => {
  var t;
  return (t = E(e)) == null ? void 0 : t.notificationSettings;
}, re = (e) => {
  var t;
  return (t = E(e)) == null ? void 0 : t.subscriptionPlan;
}, ce = (e) => {
  var t;
  return (t = E(e)) == null ? void 0 : t.team;
}, oe = (e) => {
  var t;
  return (t = nt(e)) == null ? void 0 : t.isPushNotificationEnabledGlobal;
}, ae = (e) => {
  var t;
  return (t = nt(e)) == null ? void 0 : t.isEmailNotificationEnabledGlobal;
}, ue = (e) => {
  const { accessToken: t, refreshToken: s, expires: n } = h(e);
  return { accessToken: t, refreshToken: s, expires: n };
}, he = (e) => h(e).accessToken, de = (e) => h(e).agentId, fe = () => {
  const e = U(h);
  return {
    state: e,
    isLoading: !e.status || e.status === "loading"
  };
}, Te = (e) => {
  const t = U(yt), s = U(Pt), n = U(vt), i = U(It), [c, r] = St(() => e ? t ? (typeof e == "string" ? [e] : e).reduce(
    (a, d) => {
      const _ = !!i && !!i[d];
      return a[0][d] = _, a[1] = a[1] && _, a;
    },
    [{}, !0]
  ) : [{}, !1] : [{}, !0], [e, t, i]);
  return {
    isAuthenticated: t,
    isGuest: s,
    status: n,
    isLoading: !n || n === "loading",
    hasAccess: r,
    access: c,
    roles: i
  };
}, Rt = (e) => ({
  ...e,
  mutationFn: async (t) => {
    const s = {
      ...t,
      email: t.email.toLowerCase()
    };
    return t.clientId && t.redirectUrl ? T.requestOAuthOTP({
      data: {
        ...s,
        redirect_uri: t.redirectUrl,
        client_id: t.clientId,
        state: t.state,
        codeChallenge: t.codeChallenge
      }
    }) : T.requestOTP({ data: s });
  }
}), Ct = (e) => ({
  ...e,
  mutationFn: async ({ email: t, otp: s, clientId: n, state: i, codeChallenge: c }) => n ? T.verifyOAuthOTP({
    data: {
      email: t.toLowerCase(),
      otp: s,
      client_id: n,
      state: i,
      codeChallenge: c
    }
  }) : T.verifyOTP({
    data: {
      email: t.toLowerCase(),
      otp: s
    }
  })
}), wt = (e) => ({
  ...e,
  mutationFn: async ({
    token: t,
    code: s,
    type: n,
    userDetails: i,
    clientId: c,
    redirectUrl: r,
    state: u,
    codeChallenge: a
  }) => {
    if (!n || !s && !t) throw new Error("Invalid request");
    return c && r ? t ? T.verifyOAuthTokenProvider({
      data: {
        token: t,
        type: n,
        userDetails: i,
        client_id: c,
        redirect_uri: r,
        state: u,
        codeChallenge: a
      }
    }) : T.verifyOAuthCodeProvider({
      data: {
        code: s || "",
        type: n,
        client_id: c,
        redirect_uri: r,
        state: u,
        codeChallenge: a
      }
    }) : t ? T.verifyAuthTokenProvider({
      data: { token: t, type: n, userDetails: i }
    }) : T.verifyAuthCodeProvider({
      data: { code: s || "", type: n }
    });
  }
}), Nt = (e) => ({
  ...e,
  mutationFn: async (t) => T.logout(t)
}), Se = (e) => {
  const t = g();
  return P(
    Nt({
      ...e,
      onMutate: (...s) => {
        t(l({ status: "loading" })), e != null && e.onMutate && e.onMutate(...s);
      },
      onSuccess: (...s) => {
        t(l(S.getAnonymous())), t(I()), e != null && e.onSuccess && e.onSuccess(...s);
      }
    })
  );
}, le = (e) => P(Rt(e)), _e = ({ onRedirect: e, ...t }) => {
  const s = g();
  return P(
    wt({
      ...t,
      onMutate: (...n) => {
        s(l({ status: "loading" })), t != null && t.onMutate && t.onMutate(...n);
      },
      onError: (n, i, c) => {
        s(l({ status: "initialized" })), t != null && t.onError && t.onError(n, i, c);
      },
      onSuccess: (n, i, c) => {
        var u;
        if (s(l({ status: "initialized" })), !(n != null && n.success)) return;
        const r = (u = n.data) == null ? void 0 : u.data;
        if (r) {
          if (i.clientId && i.redirectUrl) {
            "redirect_uri" in r && r.redirect_uri && e(r.redirect_uri);
            return;
          }
          if ("authToken" in r && r.authToken) {
            const { authToken: a, refreshToken: d } = r;
            s(
              $({
                accessToken: a,
                refreshToken: d
              })
            );
          }
          t != null && t.onSuccess && t.onSuccess(n, i, c);
        }
      }
    })
  );
}, me = ({ onRedirect: e, ...t }) => {
  const s = g();
  return P(
    Ct({
      ...t,
      onMutate: (...n) => {
        s(l({ status: "loading" })), t != null && t.onMutate && t.onMutate(...n);
      },
      onSuccess: (n, i, c) => {
        var u;
        if (s(l({ status: "initialized" })), !(n != null && n.success)) return;
        const r = (u = n.data) == null ? void 0 : u.data;
        if (r) {
          if (i.clientId) {
            "redirect_uri" in r && r.redirect_uri && e(r.redirect_uri);
            return;
          }
          if ("authToken" in r && r.authToken) {
            const { authToken: a, refreshToken: d } = r || {};
            if (!a) return;
            const _ = i.redirectUrl;
            s(
              $({
                ...i,
                accessToken: a,
                refreshToken: d,
                onCompleted: _ ? () => e(_) : void 0
              })
            );
          }
          t != null && t.onSuccess && t.onSuccess(n, i, c);
        }
      }
    })
  );
}, it = () => {
  const e = S.getStorageTokens();
  return e != null && e.accessToken ? [S.isTokenValid(e.accessToken, 30), e] : [!1, void 0];
}, gt = V(x, async (e) => {
  o == null || o.service(m, 3, "Refresh token interceptor lock");
  const [t, s] = it();
  if (t || !(s != null && s.refreshToken))
    return s == null ? void 0 : s.accessToken;
  const n = await S.refresh(s.refreshToken);
  if (n != null && n.accessToken)
    return e == null || e(n), n.accessToken;
}), R = async (e, t) => {
  const [s, n] = it();
  return s && e !== y(n.accessToken) ? n.accessToken : gt(t);
}, rt = lt([401]), Ee = ({ onAuthUpdated: e }) => async (t, s) => {
  var n;
  if (o == null || o.service(z, 3, "Error interceptor", t.key, s), !t._retry && rt(s)) {
    const i = await R((n = t.headers) == null ? void 0 : n.Authorization, e);
    if (!i) throw s;
    t._retry = !0, t.headers = {
      ...t.headers,
      Authorization: y(i)
    };
    return;
  }
  throw s;
}, ke = ({ onAuthUpdated: e }) => async (t, s) => {
  var n;
  if (o == null || o.service(z, 3, "Retry interceptor", t.key, s), rt(s)) {
    if (t._retry || _t(s) === ht)
      return !1;
    const i = await R((n = t.headers) == null ? void 0 : n.Authorization, e);
    if (!i) return !1;
    t._retry = !0, t.headers = {
      ...t.headers,
      Authorization: y(i)
    };
  }
  return !0;
}, Ue = ({ onRetry: e, onAuthUpdated: t }) => async (s) => {
  var i, c;
  e == null || e(s);
  const n = s == null ? void 0 : s.config;
  if (o == null || o.logWith(3, "Axios auth interceptor", s), ((i = s == null ? void 0 : s.response) == null ? void 0 : i.status) === 401 && !(n != null && n._retry) && !String(n.url).startsWith(G)) {
    n._retry = !0;
    const r = (c = n.headers.Authorization) == null ? void 0 : c.toString(), u = await R(r, t);
    return o == null || o.service(m, 3, "Axios auth interceptor", u), u ? (n.headers.Authorization = y(u), at.client(n)) : Promise.reject(s);
  }
  return Promise.reject(s);
}, Ae = ({ onRetry: e, onAuthUpdated: t }) => async (s, n) => {
  var r, u;
  e == null || e(n);
  const i = s;
  if ((n && typeof n == "object" ? (n == null ? void 0 : n.code) || (n == null ? void 0 : n.status) : n) === 401 && !(i != null && i.retried) && !String(i.url).startsWith(G)) {
    i.retried = !0;
    const a = (u = (r = i.headers) == null ? void 0 : r.Authorization) == null ? void 0 : u.toString(), d = await R(a, t);
    return d ? (i.headers = {
      ...i.headers,
      Authorization: y(d)
    }, ut.fetch(i)) : Promise.reject(n);
  }
  return Promise.reject(n);
}, b = (e) => !!e.isAuthenticated && !!e.userId && !e.isGuest, k = mt();
let A;
const Lt = (e, t) => new Promise(async (s) => {
  A && clearTimeout(A);
  const { expires: n, refreshToken: i } = e;
  if (!n || !i) return;
  const c = ~~(6e4 + Math.random() * 24e4), r = Math.max(0, n * 1e3 - Date.now() - c);
  o == null || o.service(m, 3, "Refresh auth token setup timeout", {
    time: r
  }), A = setTimeout(
    V(x, async () => {
      o == null || o.service(m, 3, "Refresh auth token timeout");
      const a = await S.refresh(e.refreshToken) || await S.logout();
      return a && (t.dispatch(l(a)), t.dispatch(a.isAuthenticated ? D() : I())), s(a);
    }),
    r
  );
}), M = async (e, t) => {
  const s = await Lt(e, t);
  return s != null && s.isAuthenticated && M(s, t), s;
}, Ot = 6e5;
let N;
const Dt = (e) => new Promise(async (t) => {
  N = setTimeout(async () => {
    const s = await L.getSubscriptionPlan(e);
    return t(s);
  }, Ot);
});
async function H(e) {
  N && clearTimeout(N);
  const t = h(e.getState());
  if (!b(t)) return;
  const s = await Dt(t.userId), n = O(s);
  n && s.success && e.dispatch(tt(n)), H(e);
}
k.startListening({
  type: p,
  effect: async (e, t) => {
    t.cancelActiveListeners();
    const s = await S.setup();
    s.refreshToken && t.fork(async () => {
      M(s, t);
    }), t.dispatch(v(s)), t.dispatch(s.isAuthenticated ? D() : I());
  }
});
k.startListening({
  type: Y,
  effect: async (e, t) => {
    var c;
    t.cancelActiveListeners();
    const s = h(t.getState());
    if (!b(s)) return;
    const n = await F(
      () => dt(L.getCurrent, {
        retries: 2
      })
    )();
    if (n != null && n.code && n.code >= 400 && n.code <= 500) {
      t.dispatch(Ut());
      return;
    }
    const i = (c = O(n)) == null ? void 0 : c.user;
    if (i) {
      const r = {
        id: i.userID,
        firstName: i.userFirstName,
        lastName: i.userLastName,
        ...i
      }, a = { ...h(t.getState()), currentUser: r };
      t.dispatch(v(a)), t.fork(() => H(t));
      return;
    }
  }
});
k.startListening({
  type: B,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  effect: async (e, t) => {
    t.cancelActiveListeners(), o == null || o.service(m, 1, "SETUP_AUTH");
    const { accessToken: s, refreshToken: n, onCompleted: i } = e.payload || {}, c = await S.setup({
      accessToken: s,
      refreshToken: n
    });
    c.refreshToken && t.fork(() => M(c, t)), c.isAuthenticated && t.fork(() => H(t)), t.dispatch(v(c)), t.dispatch(D()), t.dispatch(l({ status: "initialized" })), i && (ft(i) ? i() : t.dispatch(i));
  }
});
k.startListening({
  type: X,
  effect: async (e, t) => {
    t.cancelActiveListeners(), t.dispatch(kt());
  }
});
k.startListening({
  type: j,
  effect: async (e, t) => {
    t.cancelActiveListeners(), t.dispatch(l({ status: "loading" })), A && clearTimeout(A), h(t.getState()).isAuthenticated && await S.logout(), t.dispatch(v(S.getAnonymous())), t.dispatch(I());
  }
});
k.startListening({
  type: Q,
  effect: async (e, t) => {
    t.cancelActiveListeners();
    const s = h(t.getState());
    if (!b(s)) return;
    const n = await L.getSubscriptionPlan(s.userId), i = O(n);
    i && n.success && t.dispatch(tt(i));
  }
});
export {
  X as AUTHENTICATED,
  Y as GET_CURRENT_USER,
  p as INIT_AUTH,
  j as LOGOUT,
  Q as REFRESH_SUBSCRIPTION_PLAN,
  B as SETUP_AUTH,
  K as SET_AUTH_STATE,
  q as SET_CURRENT_USER,
  Zt as SET_CURRENT_USER_SUBSCRIPTION_PLAN,
  J as SET_SUBSCRIPTION_PLAN,
  Et as UN_AUTHENTICATED,
  Z as UPDATE_AUTH_STATE,
  W as UPDATE_CURRENT_USER,
  st as authInitialState,
  k as authListenerMiddleware,
  te as authReducer,
  et as authResetState,
  S as authService,
  D as authenticated,
  Ue as axiosAuthInterceptor,
  Ae as braidOnError,
  Ee as braidOnErrorInterceptor,
  ke as braidOnRetryInterceptor,
  R as getAccessToken,
  kt as getCurrentUser,
  Jt as initAuth,
  $t as initConfig,
  o as logger,
  Ut as logout,
  Nt as logoutMutation,
  Yt as refreshSubscriptionPlan,
  x as refreshTokenLockName,
  Rt as requestOTPMutation,
  de as selectAgentId,
  he as selectAuthAccessToken,
  h as selectAuthState,
  vt as selectAuthStatus,
  ue as selectAuthToken,
  ee as selectAuthUserId,
  E as selectCurrentUser,
  ne as selectCurrentUserCustomerId,
  ie as selectCurrentUserEmail,
  ae as selectCurrentUserEmailSetting,
  se as selectCurrentUserId,
  oe as selectCurrentUserPushSetting,
  It as selectCurrentUserRoles,
  nt as selectCurrentUserSettings,
  re as selectCurrentUserSubscriptionPlan,
  ce as selectCurrentUserTeam,
  yt as selectIsAuthenticated,
  Pt as selectIsCurrentUserGuest,
  v as setAuthState,
  Qt as setCurrentUser,
  tt as setSubscriptionPlan,
  $ as setupAuth,
  I as unauthenticated,
  l as updateAuthState,
  Xt as updateCurrentUser,
  fe as useAuth,
  Te as useHasAccess,
  Se as useLogout,
  le as useRequestOTP,
  _e as useVerifyAuthProvider,
  me as useVerifyOTP,
  wt as verifyAuthProviderMutation,
  Ct as verifyOTPMutation
};
